import React, { Component } from 'react';
import Layout from '../components/layout';

import styled from 'styled-components'
import { Link } from 'gatsby';
import Fade from 'react-reveal/Fade'

const SuccessContainer = styled.div`
    max-width: 750px;
    margin: 0 auto;
    text-align: center;
    padding: 20% 0;
`

const Title = styled.h1`
    font-size: 47px;
    color: #00b500;
`
const Subtitle = styled.p`
    width: 60%;
    margin: 0 auto;
    color: #4a4a4a;
`

const HomeButton = styled.button`
    display: block;
    text-align: center;
    margin: 30px auto;
    background-color: #fc444e;
    padding: 20px 50px;
    border-radius: 2px;
    border: none;
    color: white;
    text-decoration: none;
    font-family: 'Poppins';
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;

    &:focus {
        outline: none;
    }

`
const Logo = styled.img`
    margin-top: 50px;
    width: 180px;
    
`


class PaymentSuccessPage extends Component {
    

    render() { 
        return (
            <Layout>
                    <SuccessContainer>
                        <Fade bottom>
                        <Title>Payment Successful!</Title>
                        <Subtitle>You will receive a confirmation email along with additional information shortly.</Subtitle>
                        <Link style={{textDecoration: 'none'}}replace to="/"><HomeButton>Go Home</HomeButton></Link>
                        </Fade>
                        <hr style={{opacity: 0.2}} />
                        <Logo src="https://www.wheredpump.com/brand/downloads/assets/wordwork_color"/>
                    </SuccessContainer>
            </Layout>
        );
    }
}
 
export default PaymentSuccessPage;